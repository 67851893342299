@import '../../../styles/_colors';
@import '../../../styles/_variables';

.pluses {
    @include outerContainer;
    margin-top: 196px;

    .plusesInner {
        @include innerContainer;
        flex-direction: row;

        .sectionTitle {
            max-width: 330px;
            width: 100%;
            margin: 0 94px 0 0;
            height: 276px;
            max-height: 276px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            z-index: 5;

            .ellipse {
                @include ellipseSeaWave;
                width: 500px;
                height: 500px;
                left: -450px;
                bottom: -100%;
                opacity: 0.3;
                filter: blur(40px);
                z-index: -1;
            }
        }

        .scrollContentWrapper {
            display: flex;
            flex: 1;
            width: 100%;
            height: 100%;

            .scrollContent {
                width: 100%;
                height: 100%;

                .animationWrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    height: 200vh;
                    max-height: 1600px;
                    position: relative;

                    .itemsWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100vh;
                        max-height: 100vh;

                        .itemInner {
                            @include gradientBorder;

                            width: 100%;
                            max-width: 700px;
                            padding: 44px;
                            border: 1px solid transparent;
                            border-radius: 32px;
                            margin-bottom: 0;

                            flex-direction: column;
                            -webkit-box-pack: center;
                            -webkit-justify-content: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -webkit-flex-direction: column;
                            -ms-flex-direction: column;
                            -webkit-box-align: center;
                            -webkit-align-items: center;
                            -ms-flex-align: center;
                            align-items: center;

                            & h3 {
                                max-width: 330px;
                                margin-bottom: 25px;
                            }

                            & p {
                                max-width: 482px;
                            }
                        }
                    }
                }
            }
        }

        .paginationBlock {
            position: relative;
            width: 52px;
            max-width: 52px;
            height: auto;
            margin-left: 28px;

            .paginationInner {
                display: flex;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;

                width: 100%;
                height: 276px;
                max-height: 276px;

                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;

                flex-direction: column;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;

                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                .iconWrapper {
                    @include gradientBorder;

                    cursor: pointer;
                    width: 52px;
                    max-width: 52px;
                    height: 52px;
                    max-height: 52px;
                    margin-bottom: 16px;

                    &:active,
                    &:hover {
                        background: $main-gradient;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .cogWrapper,
                .graphWrapper {
                    padding: 13px;
                }

                .peopleWrapper {
                    padding: 15px 13px;
                }

                .editWrapper {
                    padding: 12px 14px;
                }

                .iconActive {
                    background: $main-gradient;
                    border: none;
                    padding: 13px 15px;

                    &::before {
                        content: none;
                    }
                }

                .cogWrapper.iconActive,
                .graphWrapper.iconActive {
                    padding: 14px;
                }

                .peopleWrapper.iconActive {
                    padding: 16px 14px;
                }
            }
        }

        .carouselItem {
            @include gradientBorder;
            cursor: pointer;
            border-radius: 32px;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            padding: 44px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            text-align: left;
            margin: 0 20px;
            height: 100%;

            &:before {
                border-radius: 32px;
            }

            .itemTitle {
                font-family: $main-font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 130%;
                margin-bottom: 25px;
            }

            .itemText {
                font-family: $side-font-family;
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
            }
        }

        .indicatorItem,
        .indicatorActive {
            cursor: pointer;
            display: flex;
            margin: 0 10px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $wodo-hr-grey;
            opacity: 0.2;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .indicatorActive {
            opacity: 1;
        }
    }
}

@media screen and (max-width: $tablet) {
    .pluses {
        margin-top: 110px;
        padding: 0 !important;

        .plusesInner {
            flex-direction: column;

            .sectionTitle {
                max-width: 250px;
                width: 100%;
                margin: 0 0 40px 20px;
                height: 100%;
                max-height: 100%;

                .ellipse {
                    width: 0;
                    height: 0;
                    left: 0;
                    bottom: 0;
                }
            }

            .scrollContentWrapper {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .pluses {
        margin-top: 90px;

        .plusesInner {
            .sectionTitle {
                max-width: 280px;
                width: 100%;
                margin: 0 0 30px 10px;
                height: 100%;
                max-height: 100%;
            }

            .carouselItem {
                padding: 30px 20px;
                margin: 0 10px;
            }
        }
    }
}
