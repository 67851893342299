@import '_colors';

$extra-small-mobile: 319px;
$small-mobile: 359px;
$mobile-landscape: 767px;
$tablet: 1008px;
$laptop-header: 1180px;
$laptop: 1221px;

$mobile-height: 600px;
$laptop-height: 840px;

$main-font: 'Craftwork Grotesk';
$side-font: 'Inter';

$main-font-family: $main-font, 'Arial', 'Helvetica', sans-serif;
$side-font-family: $side-font, 'Arial', 'Helvetica', sans-serif;

$main-gradient: linear-gradient(90deg, $wodo-gradient-from 0%, $wodo-gradient-to 100%);
$main-gradient-opacity: linear-gradient(90deg, $wodo-gr-from-op 8.56%, $wodo-gr-to-op 96.5%);

@mixin gradientBorder {
    position: relative;
    background-color: $wodo-icon-border;
    background-clip: padding-box;
    z-index: 0;
    border: 1px solid transparent;
    border-radius: 16px;

    &::before {
        content: '';
        position: absolute;
        inset: -1px;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(to left, $wodo-grey-gradient, $wodo-white);
        -webkit-mask: linear-gradient($wodo-white 0 0) content-box, linear-gradient($wodo-white 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
    }
}

@mixin ellipseSeaWave {
    position: absolute;
    z-index: -1;
    background-color: $wodo-sea-wave;
    opacity: 0.5;
    border-radius: 50%;
    filter: blur(40px);

    @media screen and (max-width: $mobile-landscape) {
        filter: blur(40px) !important;
    }
}

@mixin ellipseOrange {
    position: absolute;
    z-index: -1;
    background-color: $wodo-orange;
    opacity: 0.5;
    border-radius: 50%;
    filter: blur(80px);

    @media screen and (max-width: $mobile-landscape) {
        opacity: 0.3;
        filter: blur(40px) !important;
    }
}

@mixin outerContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    position: relative;

    @media screen and (max-width: $laptop) {
        padding: 0 25px;
    }

    @media screen and (max-width: $tablet) {
        padding: 0 20px;
    }

    @media screen and (max-width: $mobile-landscape) {
        padding: 0 10px;
    }
}

@mixin innerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

@mixin checkboxTemplate {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 310px;
    margin-bottom: 23px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

@mixin checkbox {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 3px;
    border-radius: 4px;
    border: 1px solid $wodo-hr-grey;
    margin-right: 9px;
}

@mixin arrowBtn {
    @include gradientBorder;

    cursor: pointer;
    display: flex;
    width: 58px;
    max-width: 58px;
    height: 58px;
    max-height: 58px;
    margin-bottom: 0;
    padding: 23px 16px;
    border-radius: 50% !important;
    position: absolute;
    z-index: 2;

    &:active,
    &:hover {
        background: $main-gradient-opacity;
    }

    &:before {
        border-radius: 50%;
    }
}

@mixin pixelBg {
    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.8;
        background-image: url('../assets/backgroundImage.svg');
        background-position: 50% 0;
    }
}
