@import '../../../styles/_colors';
@import '../../../styles/_variables';

.howToStart {
    @include outerContainer;
    margin-top: 160px;

    .howToStartInner {
        @include innerContainer;
        flex-direction: row;

        .sectionTitle {
            max-width: 330px;
            width: 100%;
            margin: 0 82px 0 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            & span {
                max-width: 300px;
            }
        }

        .stepsBlock {
            display: flex;
            width: 100%;
            flex-direction: column;

            .howToStartHr {
                margin: 0;
                padding: 0;
                height: 1px;
                opacity: 0.4;
                border-top: 1px solid $wodo-hr-grey;
                width: 100%;
            }

            .itemWrapper {
                display: flex;
                flex-direction: row;
                margin: 30px 0;
                align-items: flex-start;
                justify-content: space-between;
                z-index: 3;

                & h3 {
                    width: 100%;
                    min-width: 120px;
                    max-width: 200px;
                    margin-left: 90px;
                }

                & p {
                    margin-left: 56px;
                    max-width: 360px;
                }
            }
        }
    }

    .btnWrapper {
        margin-top: 50px;
    }

    .ellipseOrange {
        @include ellipseOrange;

        z-index: 0;
        width: 493px;
        height: 387px;
        bottom: -30%;
        right: -13%;
        filter: blur(137px);
    }
}

@media screen and (max-width: $laptop) {
    .howToStart {
        margin-top: 110px;
        .howToStartInner {
            flex-direction: column;
            .sectionTitle {
                margin-bottom: 40px;
            }
            .stepsBlock {
                .itemWrapper {
                    & h3 {
                        width: 100%;
                        min-width: 120px;
                        max-width: 200px;
                        margin-left: 40px;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 130%;
                    }

                    & p {
                        margin-left: 63px;
                        max-width: 360px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $mobile-landscape) {
    .howToStart {
        margin-top: 90px;
        .howToStartInner {
            .sectionTitle {
                margin-bottom: 30px;
            }
            .stepsBlock {
                .itemWrapper {
                    flex-direction: column;
                    & h3 {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 10px;
                        font-weight: 600;
                    }

                    & p {
                        margin-left: 0;
                        margin-top: 15px;
                        max-width: 360px;
                    }
                }
            }
        }

        .ellipseOrange {
            width: 353px;
            height: 267px;
            bottom: -10%;
            right: -10%;
        }
    }
}
