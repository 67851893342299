@import '../../../styles/_colors';
@import '../../../styles/_variables';

.main {
    @include outerContainer;
    max-width: 1240px;
    margin-top: 190px;
    margin-bottom: 70px;

    .mainInner {
        @include innerContainer;
        z-index: 3;

        .sectionTitle {
            max-width: 360px;
            width: 100%;
            margin: 0 0 70px 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            & span {
                max-width: 230px;
            }
        }

        .iconWrapper {
            @include arrowBtn;
        }

        .rotate0 {
            bottom: 0;
            left: calc(50% - 135px);
        }

        .rotate180 {
            bottom: 0;
            right: calc(50% - 135px);

            & svg {
                transform: rotate(180deg);
            }
        }

        .carouselItem {
            @include gradientBorder;
            cursor: pointer;
            border-radius: 32px;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            padding: 146px 62px 146px 67px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            margin: 0 20px;
            height: 100%;

            &:before {
                border-radius: 32px;
            }

            .itemLeftBlock {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 109px;
                min-width: 244px;

                .magnitLogo,
                .openLogo,
                .ancorLogo {
                    min-width: 200px;
                    width: 200px;
                    min-height: 37.6px;
                    height: 37.6px;
                }

                .itemName {
                    font-family: $main-font-family;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 130%;
                    margin-top: 46px;
                    margin-bottom: 9px;
                }

                .itemRole {
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 130%;
                    color: $wodo-hr-grey;
                    text-align: center;
                }
            }

            .itemRightBlock {
                font-family: $main-font-family;
                font-weight: 400;
                font-size: 21px;
                line-height: 130%;
            }
        }

        .indicatorItem,
        .indicatorActive {
            cursor: pointer;
            display: flex;
            margin: 0 10px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $wodo-hr-grey;
            opacity: 0.2;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .indicatorActive {
            opacity: 1;
        }
    }

    .ellipseOrange {
        @include ellipseOrange;
        z-index: 0;
        width: 389px;
        height: 605px;
        bottom: -30%;
        left: -450px;
    }
}


@media screen and (max-width: $laptop) {
    .main {
        padding: 0;
    }
}

@media screen and (max-width: $tablet) {
    .main {
        margin-top: 110px;
        margin-bottom: 0;

        .mainInner {
            .sectionTitle {
                display: none;
            }

            .iconWrapper {
                display: none;
            }

            .carouselItem {
                padding: 95px 34px 94px 34px;

                .itemLeftBlock {
                    margin-right: 80px;
                }
            }
        }

        .ellipseOrange {
            z-index: 0;
            width: 350px;
            height: 470px;
            bottom: -65%;
            left: -70%;
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .main {
        margin-top: 90px;

        .mainInner {
            .carouselItem {
                flex-direction: column;
                padding: 50px 20px;

                .itemLeftBlock {
                    margin-right: 0;
                    margin-bottom: 50px;
                }
                .itemRightBlock {
                    margin: 0;
                    font-family: $side-font-family;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 140%;
                }
            }
        }
    }
}