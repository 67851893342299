@import '../../../styles/_colors';
@import '../../../styles/_variables';

.inputContainer {
    display: flex;
    width: 100%;
    min-width: 300px;
    max-width: 350px;
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
    border-radius: 16px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .inputWrapper,
    .errorWrapper {
        .input {
            font-family: $side-font-family;
            width: 100%;
            height: 54px;
            color: $wodo-black;
            font-weight: 500;
            font-size: 17px;
            line-height: 130%;
            padding: 16px 20px;
            border-radius: 16px;
            background: transparent;
            border: none;

            &::placeholder {
                color: $wodo-hr-grey;
            }

            &:active,
            &:hover,
            &:focus,
            &:focus-within {
                outline: none;
            }
        }
    }

    .inputWrapper {
        @include gradientBorder;
        width: 100%;
        height: 54px;
    }

    .errorWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 69px;

        .input {
            width: 100%;
            height: 54px;
            position: relative;

            z-index: 0;
            border: 1px solid $wodo-red;
            border-radius: 16px;
        }

        .errorText {
            color: $wodo-red;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            padding-left: 20px;
        }
    }
}

@media screen and (max-width: $laptop) {
    .inputContainer {
        max-width: 100%;
    }
}