@import '_colors';
@import '_variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('./fonts/CG/CraftworkGrotesk-Regular.eot');
    src: url('./fonts/CG/CraftworkGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CG/CraftworkGrotesk-Regular.woff2') format('woff2'),
    url('./fonts/CG/CraftworkGrotesk-Regular.woff') format('woff'),
    url('./fonts/CG/CraftworkGrotesk-Regular.ttf') format('truetype'),
    url('./fonts/CG/CraftworkGrotesk-Regular.svg#CraftworkGrotesk-Regular') format('svg');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('./fonts/CG/CraftworkGrotesk-SemiBold.eot');
    src: url('./fonts/CG/CraftworkGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CG/CraftworkGrotesk-SemiBold.woff2') format('woff2'),
    url('./fonts/CG/CraftworkGrotesk-SemiBold.woff') format('woff'),
    url('./fonts/CG/CraftworkGrotesk-SemiBold.ttf') format('truetype'),
    url('./fonts/CG/CraftworkGrotesk-SemiBold.svg#CraftworkGrotesk-SemiBold') format('svg');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('./fonts/CG/CraftworkGrotesk-Heavy.eot');
    src: url('./fonts/CG/CraftworkGrotesk-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CG/CraftworkGrotesk-Heavy.woff2') format('woff2'),
    url('./fonts/CG/CraftworkGrotesk-Heavy.woff') format('woff'),
    url('./fonts/CG/CraftworkGrotesk-Heavy.ttf') format('truetype'),
    url('./fonts/CG/CraftworkGrotesk-Heavy.svg#CraftworkGrotesk-Heavy') format('svg');
    font-weight: 900;
    font-display: swap;
}


body {
    margin: 0;
    font-family: $side-font-family;
    color: $wodo-black;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    padding: 0;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
    font-family: $main-font-family;
    color: $wodo-black;
    margin: 0;
    padding: 0;
}

h1, h2 {
    font-weight: 900;
}

h3 {
    font-weight: 600;
}

h4 {
    font-weight: 500;
}

h1 {
    font-size: 80px;
    line-height: 110%;
}

h2 {
    font-size: 37px;
    line-height: 130%;
}

h3 {
    font-size: 21px;
    line-height: 130%;
}

h4 {
    font-size: 19px;
    line-height: 130%;
}

p {
    font-family: $side-font-family;
    margin: 0;
    padding: 0;
    color: $wodo-black;
    font-size: 17px;
    line-height: 130%;
}

a {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    color: $wodo-black;
    text-decoration: none;

    &:hover,
    &:active {
        color: $wodo-sea-wave;
    }
}

.btn,
.btn-outline,
.btn-outline-reverse {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    text-align: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding: 18px 27px;
    border: none;
    z-index: 5;
    margin: 0 0 6px;
    border-radius: 30px;
    text-decoration: none;
    color: $wodo-white;
    white-space: nowrap;
    background-color: $wodo-orange;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.btn-outline {
    background-color: transparent;
    outline: 1px solid $wodo-black;
    color: $wodo-black;
}

.btn-outline-reverse {
    outline: none;
    border: 2px solid $wodo-orange;
    transition: all 0.3s ease;
    height: 58px;
    max-height: 58px;
    padding: 16px 27px;
    margin: 0;
}

.btn-outline-reverse:hover,
.btn-outline-reverse:active {
    background-color: transparent;
    border: 2px solid $wodo-small-text;
    color: $wodo-white;
}


@media screen and (max-width: $small-mobile) {
    .btn {
        margin: 0;
    }
}

.btn-fill-right::before {
    position: absolute;
    content: "";
    background: $wodo-black;
    transition-duration: 0.4s;
    z-index: -1;
    inset: 0 auto auto 0;
    width: 0;
    height: 100%;
    opacity: 1;
}

.btn-fill-right:hover::before {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.btn-fill-right:hover {
    color: $wodo-white;
    background: $wodo-black;
    transition: color 0.4s ease 0s, background 0s ease 0.4s;
}

.btn-disabled {
    transition: none;
    color: $wodo-white;
    background: $wodo-hr-grey;

    &:hover,
    &:active {
        transition: none;
        background: $wodo-hr-grey;
    }

    &:before {
        content: none;
    }
}

.app {
    display: flex;
    min-height: 100vh;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: $wodo-white;
}

.app-inner {
    @include pixelBg;
}

.main-btn-fade-in {
    opacity: 0;
    overflow: hidden;
}

.title-split-line {
    display: block;
    position: relative;
    overflow: hidden;
}

.title-showed .title-split-line {
    overflow: visible !important;
}

.title-split-char, .title-split-deep-char {
    display: inline-block !important;
}

#wrapper {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $wodo-wrapper;
}

#content {
    overflow: hidden;
    width: 100%;
    z-index: 2;
}

/* ANIMATIONS */

@-webkit-keyframes rotating /* Safari and Chrome */
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

$circlePathFrom: rotate(0deg) translateX(135px) rotate(0deg);
$circlePathTo: rotate(360deg) translateX(135px) rotate(-360deg);
$circlePathDuration: 16s;

@-webkit-keyframes circlePath {
    from {
        -webkit-transform: $circlePathFrom
    }
    to {
        -webkit-transform: $circlePathTo
    }
}

@-moz-keyframes circlePath {
    from {
        -moz-transform: $circlePathFrom
    }
    to {
        -moz-transform: $circlePathTo
    }
}

@-o-keyframes circlePath {
    from {
        -o-transform: $circlePathFrom
    }
    to {
        -o-transform: $circlePathTo
    }
}

@keyframes circlePath {
    from {
        transform: $circlePathFrom
    }
    to {
        transform: $circlePathTo
    }
}

@media screen and (min-width: $laptop-header) {
    .circle-path {
        -webkit-animation: circlePath $circlePathDuration linear infinite;
        -moz-animation: circlePath $circlePathDuration linear infinite;
        -o-animation: circlePath $circlePathDuration linear infinite;
        animation: circlePath $circlePathDuration linear infinite;
    }

    .rotating {
        -webkit-animation: rotating 10s linear infinite;
        -moz-animation: rotating 10s linear infinite;
        -o-animation: rotating 10s linear infinite;
        animation: rotating 10s linear infinite;
    }
}

/* */


.scroll-content-wrapper {
    height: 200vh;
}

.green {
    width: 100%;
    min-height: 600px;
    background-color: green;
}

.blue {
    width: 100%;
    min-height: 600px;
    background-color: blue;
}

.carousel-slider {
    padding-bottom: 93px;
}

.carousel .control-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 58px;
    min-height: 58px;
    margin: 0;
}


@media screen and (max-width: $laptop) {
    p {
        font-size: 15px;
        line-height: 140%;
    }

    .main-btn-fade-in {
        opacity: 1;
    }
}

@media screen and (max-width: $tablet) {
    #wrapper {
        overflow: auto;
        position: relative;
    }

    #content {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        z-index: 2;
    }

    .carousel-slider {
        padding-bottom: 40px;
    }

    .carousel .control-dots {
        height: 10px;
        min-height: 10px;
    }
}

@media screen and (max-width: $mobile-landscape) {
    h1 {
        font-size: 44px;
        line-height: 110%;
    }

    h2 {
        font-size: 28px;
        line-height: 130%;
    }

    h3 {
        font-size: 20px;
        line-height: 130%;
    }

    h4 {
        font-size: 19px;
        line-height: 130%;
    }

    p {
        font-size: 15px;
        line-height: 140%;
    }

    .carousel-slider {
        padding-bottom: 35px;
    }

    .btn {
        width: 100%;
        max-width: 100%;
    }
}
