@import '../../../styles/_colors';
@import '../../../styles/_variables';

.parallaxContainer {
    position: absolute;
    top: calc(100vh/2);
    transform: translateY(-50%);
    right: 0;
    max-width: 1200px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 15;
    overflow: visible;

    .parallaxWrapper {
        display: flex;
        position: relative;
        height: 667px;
        overflow: hidden;
        width: 100%;
        border-radius: 266px 0 0 133px;

        img {
            position: absolute;
            right: 0;
            margin: 0 auto;
            height: 110%;
            width: 100%;
            object-fit: cover;
            z-index: 8;
        }
    }

    .ellipse {
        @include ellipseSeaWave;
        width: 188px;
        height: 188px;
        bottom: -30px;
        left: -7px;
        z-index: 0;
    }

    .ellipseOrange {
        @include ellipseOrange;
        width: 442px;
        height: 327px;
        top: -28px;
        left: 111px;
        z-index: 0;
    }
}

@media screen and (max-width: $tablet) {
    .parallaxContainer {
        top: 64px;
        width: 83%;
        transform: none;

        .parallaxWrapper {
            height: 493px;
            border-radius: 200px 0 0 100px;
            z-index: 10;
        }

        .ellipse {
            width: 140px;
            height: 140px;
            bottom: -25px;
            left: 30px;
        }

        .ellipseOrange {
            width: 200px;
            height: 130px;
            filter: blur(40px);
            top: -20px;
            right: 111px;
        }
    }
}


@media screen and (min-width: $mobile-landscape) and (max-height: $laptop-height)  {
    .parallaxContainer {
        .parallaxWrapper {
            height: 600px;
        }
    }
}

@media screen and (min-width: $mobile-landscape) and (max-height: $laptop-height)  {
    .parallaxContainer {
        .parallaxWrapper {
            height: 66vh;
            border-radius: 30vh 0 0 15vh;
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .parallaxContainer {
        top: 64px;
        width: 97%;
        transform: none;
        overflow: visible;
        z-index: 99;

        .parallaxWrapper {
            height: 300px;
            border-radius: 64px 0 0 64px;
            z-index: 100;
        }

        .ellipse {
            width: 0;
            height: 0;
            bottom: -15px;
            left: 30px;
            z-index: -200;
        }

        .ellipseOrange {
            width: 200px;
            height: 130px;
            filter: blur(40px);
            top: -20px;
            right: 111px;
            z-index: 99;
        }
    }
}

