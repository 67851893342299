@import '../../../styles/_colors';
@import '../../../styles/_variables';

@mixin imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $wodo-block-bg;
    border: 1px solid $wodo-block-bg;
}

.secondSection {
    @include outerContainer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .sectionTitle {
        max-width: 570px;
        width: 570px;
        margin: 0 0 60px 0;
        display: flex;
        text-align: left;
        flex-direction: column;
    }

    .imagesBlockContainer {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        flex-direction: column;
        width: 100%;
        max-width: 780px;

        .webScreenImageBlock {
            @include imageContainer;
            border-radius: 10px;
            padding: 19px 8px 7px 7px;
            position: relative;
            margin-bottom: 160px;
            margin-right: 106px;
            z-index: 2;

            .webScreenImg {
                width: 100%;
                max-width: 660px;
                height: 100%;
                max-height: 425px;
            }
        }

        .mobileScreenImageBlock {
            @include imageContainer;
            position: absolute;
            z-index: 2;
            bottom: -100px;
            left: 120px;
            border-radius: 27px;
            padding: 9px;

            .mobileScreenImg {
                width: 100%;
                max-width: 185px;
                height: 100%;
                max-height: 399px;
            }
        }

        .rotatingImgWrapper {
            position: absolute;
            right: -15px;
            top: -115px;
            z-index: 1;

            img {
                width: 237px;
                min-width: 237px;
                height: 237px;
                min-height: 237px;

            }
        }

        .underImgTextBlock {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            z-index: 3;

            & p {
                max-width: 340px;
            }

            & p:first-child {
                margin-right: 60px;
            }
        }
    }

    .ellipse {
        @include ellipseSeaWave;
        width: 684px;
        height: 506px;
        left: -289px;
        bottom: -10%;
        opacity: 0.3;
        filter: blur(70px);
        z-index: 0;
    }
}

@media screen and (max-width: $tablet) {
    .secondSection {
        .sectionTitle {
            max-width: 100%;
            width: 100%;
        }

        .imagesBlockContainer {
            margin-right: 0;

            .webScreenImageBlock {
                @include imageContainer;
                margin-bottom: 160px;
                margin-right: 0;

                .webScreenImg {
                    width: 100%;
                    max-width: 660px;
                    height: 100%;
                    max-height: 425px;
                }
            }


            .underImgTextBlock {
                & p {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }

            .mobileScreenImageBlock {
                .mobileScreenImg {
                    width: 100%;
                    max-width: 182px;
                    height: 100%;
                    max-height: 371px;
                }
            }

            .rotatingImgWrapper {
                right: -30px;
                top: -70px;

                & img {
                    width: 152px;
                    min-width: 152px;
                    height: 152px;
                    min-height: 152px;
                }
            }
        }
        .ellipse {
             transform: scale(.7);
             left: -400px;
         }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .secondSection {
        .sectionTitle {
            max-width: 100%;
            width: 100%;
        }

        .imagesBlockContainer {
            .mobileScreenImageBlock {
                padding: 4px;
                border-radius: 15px;
                .mobileScreenImg {
                    width: 100%;
                    max-width: 95px;
                    height: 100%;
                    max-height: 185px;
                }
            }

            .underImgTextBlock {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;

                & p {
                    max-width: 100%;
                    margin-right: 0;

                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
            }

            .rotatingImgWrapper {
                display: none;
            }
        }

        .ellipse {
            transform: scale(.6);
            left: -450px;
            bottom: 0;
            z-index: 2;
        }
    }
}

@media screen and (max-width: $mobile-landscape) and (orientation: landscape) {
    .secondSection {
        .imagesBlockContainer {
            .underImgTextBlock {
                & p {
                    font-size: 15px !important;
                    max-width: 100%;
                    margin-right: 0;
                    transform: scale(1);

                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
