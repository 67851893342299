@import '../../../styles/_colors';
@import '../../../styles/_variables';

.documentsMain {
    @include outerContainer;
    margin-top: 190px;
    margin-bottom: 190px;

    .documentsInner {
        @include innerContainer;

        .sectionTitle {
            max-width: 360px;
            width: 100%;
            margin: 0 0 70px 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            & span {
                max-width: 360px;
            }
        }

        .buttonsBlock {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;

            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            .item {
                @include gradientBorder;

                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                padding: 49px 50px 41px;
                width: 100%;
                max-width: 360px;
                max-height: 256px;

                p {
                    min-width: 200px;
                }

                .downloadRow {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 24px;
                    margin-top: 32px;

                    & span {
                        color: $black;
                        line-height: 140%;
                    }
                }


                &:nth-child(2) {
                    margin: 0 60px
                }

                &:hover,
                &:active {
                    background: $main-gradient-opacity;
                }
            }
        }

        .logoBlock {
            display: none;

            .wodoLogo {
                display: none;
            }

            .ellipseOrange {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $laptop) {
    .documentsMain {
        .documentsInner {
            .buttonsBlock {
                flex-wrap: wrap;
                align-items: flex-start;

                .item {
                    min-width: 300px;
                    max-width: 355px;
                    min-height: 208px;
                    height: 100%;
                    padding: 35px 47px;
                    font-size: 15px;
                    line-height: 140%;
                    margin-bottom: 20px;

                    &:nth-child(2) {
                        margin: 0 0 20px 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .documentsMain {
        margin-top: 110px;
        margin-bottom: 140px;

        .documentsInner {
            .sectionTitle {
                margin: 0 0 40px 0;
            }

            .logoBlock {
                display: flex;
                position: relative;
                margin-top: 110px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 1;

                .wodoLogo {
                    display: block;
                    width: 100%;
                    max-width: 162px;
                    height: 100%;
                    max-height: 43px;
                    margin-bottom: 55px;
                }

                .ellipseOrange {
                    display: block;
                    @include ellipseOrange;
                    width: 402px;
                    height: 113px;
                    bottom: -28px;
                    filter: blur(40px);
                    z-index: 0;
                }
            }
        }
    }
}


@media screen and (max-width: $mobile-landscape) {
    .documentsMain {
        margin-top: 90px;
        margin-bottom: 110px;

        .documentsInner {
            .sectionTitle {
                margin: 0 0 30px 0;
            }

            .logoBlock {
                margin-top: 90px;
            }

            .buttonsBlock {
                .item {
                    padding: 27px 16px 27px 24px;
                    min-width: 270px;
                    width: 100%;
                    max-width: 355px;
                    min-height: 182px;
                    height: 100%;

                    .downloadRow {
                        margin-top: 22px;
                    }
                }
            }
        }
    }
}

