@import '../../styles/_colors';
@import '../../styles/_variables';

.popupAll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 200;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    .popupWrapper {
        display: flex;
        background-color: $wodo-white;
        width: 100%;
        max-width: 1160px;

        .popupContainer {
            @include pixelBg;
            position: relative;
            z-index: 200;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: $wodo-wrapper;

            .innerBlock {
                height: auto;
                position: relative;
                padding: 35px 45px 50px 55px;
                overflow: hidden;

                .ellipse {
                    @include ellipseSeaWave;
                    width: 752px;
                    height: 752px;
                    z-index: 3;
                    left: -400px;
                    top: -550px;
                    filter: blur(100px);
                }

                .closeIcon {
                    cursor: pointer;
                    position: absolute;
                    right: 9px;
                    top: 9px;
                    z-index: 400;
                }

                .popupHeader {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                    margin-bottom: 32px;
                    width: 100%;
                    z-index: 5;

                    & h3 {
                        max-width: 358px;
                        border-right: 1px solid $wodo-popup-border;
                        font-weight: 900;
                    }

                    & p {
                        margin-left: 22px;
                        max-width: 350px;
                    }

                    .wodoLogo {
                        position: absolute;
                        top: 0;
                        right: 18px;
                        width: 121.57px;
                        max-width: 122px;
                        height: 32px;
                        max-height: 32px;
                    }
                }

                .popupBody {
                    display: flex;
                    flex-direction: row;

                    .popupBodyRow {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        margin-right: 28px;

                        .rowTitle {
                            color: $wodo-black;
                            font-size: 14px;
                            line-height: 140%;
                            margin-bottom: 15px;
                            z-index: 5;

                            & span {
                                color: $wodo-orange;
                            }
                        }

                        .checkboxTip {
                            margin-left: 29px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                        }

                        &:last-child {
                            margin-right: 0;

                            .rowTitle {
                                margin-bottom: 19px;
                            }
                        }
                    }
                }

                .popupFooter {
                    display: flex;
                    flex-direction: row;
                    margin-top: -12px;
                    width: 100%;

                    .leftBlock,
                    .rightBlock {
                        display: flex;
                        flex-direction: column;
                    }

                    .leftBlock {
                        width: 69%;
                        margin-right: 30px;
                        min-width: 628px;
                        margin-top: -11px;

                        & span {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                            margin-bottom: 14px;
                        }
                    }

                    .rightBlock {
                        margin-top: 37px;
                        width: 33%;

                        .btnWrapper {
                            display: flex;
                            position: relative;

                            .ellipseOrangeSmall {
                                @include ellipseOrange;
                                width: 380px;
                                height: 200px;
                                z-index: 2;
                                transform: translate(-22%, -40%);
                            }

                            .globalErrorText {
                                position: absolute;
                                display: flex;
                                bottom: -20px;
                                color: $wodo-red;
                                text-align: left;
                                width: 100%;
                                font-size: 13px;
                                line-height: 130%;
                            }
                        }
                    }
                }


                .sentBlock {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-top: 110px;

                    .sentBlockTitle {
                        font-family: $main-font-family;
                        font-style: normal;
                        font-weight: 900;
                        font-size: 37px;
                        line-height: 130%;
                        margin-bottom: 35px;
                    }

                    .sentBlockText {
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 130%;
                        color: $black;
                        margin-bottom: 96px;
                    }

                    .sentBtnWrapper {
                        position: relative;

                        .ellipseOrange {
                            @include ellipseOrange;
                            width: 400px;
                            height: 220px;
                            z-index: 2;
                            transform: translate(-12%, -55%);
                        }
                    }

                    .sentBtnHint {
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: $wodo-black;
                        margin-top: 15px;
                        margin-bottom: 78px;
                        z-index: 25;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .popupAll {
        .popupWrapper {
            .popupContainer {
                .innerBlock {
                    padding: 35px 22px 45px;

                    .popupHeader {
                        & h3 {
                            min-width: 362px;
                            max-width: 362px;
                        }

                        .wodoLogo {
                            display: none;
                        }
                    }

                    .popupBody {
                        flex-wrap: wrap;
                        justify-content: flex-end;

                        .popupBodyRow {
                            min-width: 350px;
                            margin-right: 9px;

                            &:nth-child(2) {
                                margin-right: 0;
                            }

                            &:last-child {
                                max-width: 49%;
                                margin-left: 10px;
                            }
                        }
                    }

                    .popupFooter {
                        margin-top: -200px;
                        flex-direction: column;
                        max-width: 49%;

                        .leftBlock,
                        .rightBlock {
                            width: 100%;
                            min-width: 100%;
                            flex-direction: column;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .popupAll {
        height: 100%;
        max-height: 100vh;
        overflow: auto;
        display: flex;
        align-items: flex-start;

        .popupWrapper {
            .popupContainer {
                margin: 0;
                .innerBlock {
                    padding: 35px 10px 85px;

                    .closeIcon {
                        right: 15px;
                        top: 25px;
                    }

                    .popupHeader {
                        flex-direction: column;

                        & h3 {
                            width: 100%;
                            max-width: 100%;
                            border-right: none;
                            padding-bottom: 20px;
                            border-bottom: 1px solid $wodo-popup-border;
                        }

                        & p {
                            margin-left: 0;
                            width: 100%;
                            max-width: 100%;
                            margin-top: 20px;
                        }

                        .wodoLogo {
                            display: none;
                        }
                    }

                    .popupBody {
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;

                        .popupBodyRow {
                            width: 100%;
                            min-width: 350px;
                            margin-right: 0;

                            &:nth-child(2) {
                                margin-right: 0;
                                max-width: 100%;
                            }

                            &:last-child {
                                margin-left: 0;
                                max-width: 100%;
                            }
                        }
                    }

                    .popupFooter {
                        margin-top: 30px;
                        flex-direction: column;
                        max-width: 100%;
                        justify-content: center;
                        align-items: center;
                        margin-right: 0;
                        margin-left: 0;

                        .leftBlock,
                        .rightBlock {
                            width: 100%;
                            min-width: 100%;
                            flex-direction: column;
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small-mobile) {
    .popupAll {
        .popupWrapper {
            .popupContainer {
                .innerBlock {
                    .popupHeader {
                        & h3 {
                            min-width: 280px;
                        }
                    }

                    .closeIcon {
                        right: 15px;
                        top: 15px;
                    }

                    .popupBody {
                        .popupBodyRow {
                            width: 100%;
                            min-width: 300px;
                        }
                    }
                }
            }
        }
    }
}
