@import '../../styles/_colors';
@import '../../styles/_variables';

.footerMobile {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 50px 10px 79px;
    background-color: $wodo-black;
    overflow: hidden;

    .footerMobileSection {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;

        .infoItem {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            width: 100%;
            color: $wodo-white;
            line-height: 130%;

            .infoTitle {
                font-family: $main-font-family;
                font-weight: 900;
                font-size: 26px;
                line-height: 130%;
                font-style: normal;
                margin-bottom: 15px;
            }

            .footerHr {
                margin: 0;
                padding: 0;
                height: 1px;
                opacity: 0.4;
                border-top: 1px solid $wodo-hr-grey;
                width: 100%;
            }

            .infoText {
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
                margin-top: 25px;
            }

            .raketaLogo {
                cursor: pointer;
                width: 128px;
                height: 60px;
                margin-top: 15px;
            }

            .storesWrapper {
                display: flex;
                flex-direction: column;
                margin-top: 15px;

                .googlePlayIcon {
                    cursor: pointer;
                    width: 162px;
                    height: 48px;
                    margin-bottom: 16px;
                }

                .appStoreIcon {
                    cursor: pointer;
                    width: 144px;
                    height: 48px;
                }
            }

            .contactBlocks {
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                margin-bottom: 31px;

                .contactBlocksText {
                    max-width: 275px;
                    margin-top: 6px;
                }

                a {
                    color: $wodo-white;

                    &:hover,
                    &:active {
                        color: $wodo-sea-wave;
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }

            &:nth-child(2) {
                margin: 60px 0;
            }
        }
    }
}

.footerBlurred {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

@media screen and (min-width: $mobile-landscape) {
    .footerMobile {
        padding: 36px 22px 46px;

        .footerMobileSection {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            .infoItem {
                &:nth-child(2) {
                    margin: 0 20px;
                }
            }
        }
    }
}

@media screen and (min-width: $tablet) {
    .footerMobile {
        display: none;
    }
}