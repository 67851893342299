@import '../../../styles/_colors';
@import '../../../styles/_variables';

.requirementsMain {
    @include outerContainer;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .requirementsInner {
        @include innerContainer;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .sectionTitle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: 360px;
            width: 100%;
            margin: 0 60px 0 0;

            & span {
                max-width: 300px;
            }
        }

        .instructionBlock {
            @include gradientBorder;

            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 35px 43px;

            .instructionText {
                max-width: 450px;
                margin-bottom: 24px;
            }

            .instructionLink {
                cursor: pointer;
                color: $black;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                text-decoration: underline;
            }
        }
    }

    .ellipse {
        @include ellipseSeaWave;
        width: 684px;
        height: 506px;
        left: -25%;
        bottom: -170%;
        opacity: 0.3;
        filter: blur(70px);
        transform: rotate(-120deg);
        z-index: 0;
    }
}

@media screen and (max-width: $tablet) {
    .requirementsMain {
        margin-top: 110px;
        min-height: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        .requirementsInner {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .sectionTitle {
                margin: 0 0 40px 0;
            }

            .instructionBlock {
                margin-bottom: 0;
                padding: 32px 44px;
            }
        }
    }
}


@media screen and (max-width: $mobile-landscape) {
    .requirementsMain {
        margin-top: 90px;

        .requirementsInner {
            .instructionBlock {
                margin-bottom: 0;
                padding: 23px 20px 29px;
            }
        }

        .ellipse {
            @include ellipseSeaWave;
            width: 450px;
            height: 380px;
            right: auto;
            left: -70%;
            bottom: -80%;
            opacity: 0.3;
            filter: blur(70px);
            transform: rotate(-120deg);
            z-index: 0;
        }
    }
}
