@import '../../../styles/_colors';
@import '../../../styles/_variables';

.textareaContainer {
    display: flex;
    width: 100%;
    max-width: 800px;
    position: relative;
    z-index: 10;
    border-radius: 16px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .textareaWrapper {
        @include gradientBorder;
        width: 100%;
        height: 100%;

        .textarea {
            font-family: $side-font-family;
            width: 100%;
            height: 100%;
            color: $wodo-black;
            font-weight: 500;
            font-size: 17px;
            line-height: 130%;
            padding: 16px 18px;
            border-radius: 16px;
            background: transparent;
            border: none;
            resize: none;
            overflow: hidden;

            &::placeholder {
                color: $wodo-hr-grey;
                font-size: 14px;
                line-height: 140%;
            }

            &:active,
            &:hover,
            &:focus,
            &:focus-within {
                outline: none;
            }
        }
    }
}
