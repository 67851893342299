@import '../../../styles/_colors';
@import '../../../styles/_variables';

.supportWork {
    @include outerContainer;
    margin-top: 220px;

    .supportWorkInner {
        @include innerContainer;
        flex-direction: row;
        justify-content: space-between;
        z-index: 3;

        .leftBlock {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 360px;
            padding-top: 145px;
            margin-right: 93px;

            .sectionTitle {
                width: 100%;
                margin: 0 0 40px 0;

                & span {
                    max-width: 350px;
                }
            }

            .contactEmail {
                font-size: 17px;
                line-height: 130%;
            }
        }

        .rightBlock {
            @include gradientBorder;
            display: flex;
            position: relative;
            height: 650px;
            max-height: 650px;
            min-width: 728px;
            width: 100%;
            max-width: 780px;
            border-radius: 32px;
            background: rgba(255, 255, 255, 0.2);

            .rightBlockInner {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 30px 47px;
                justify-content: space-between;

                .slider {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: $wodo-slider;
                    border: 1px solid $wodo-hr-grey;
                    border-radius: 42px;
                    padding: 5px;
                    min-width: 299px;
                    width: 100%;
                    max-width: 360px;
                    font-size: 14px;
                    line-height: 140%;

                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;

                    .sliderItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        min-width: 175px;
                        width: 175px;
                        max-width: 175px;
                    }

                    .sliderActive {
                        background-color: $wodo-white;
                        border-radius: 42px;
                        padding: 8px 16px;
                    }

                }

                .mainBlockRow {
                    display: flex;

                    .mainBlockItem {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        flex: 1;

                        .mainBlockImgWrapper {
                            margin-top: 3px;
                            margin-bottom: -10px;
                        }

                        .mainHint {
                            width: 320px;
                            max-width: 320px;
                            margin-left: 27px;
                            text-align: center;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }

                    .hide {
                        display: none;
                    }
                }

                .mainBlockColumn {
                    display: flex;

                    .mainBlockItem {
                        display: flex;
                        flex-direction: column;

                        .mainBlockImgWrapper {
                            margin-left: -36px;
                            margin-top: 15px;
                        }

                        .mainHint {
                            margin-left: 7px;
                            height: 53px;
                            margin-top: -34px;
                            margin-bottom: 6px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }

                    .hide {
                        display: none;
                    }
                }

                .pagination {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    min-height: 52px;
                    height: 52px;
                    padding: 0 52px;
                    max-width: 310px;
                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;

                    .iconWrapper {
                        @include arrowBtn;
                    }

                    .paginationItem {
                        cursor: pointer;
                        min-width: 32px;
                        width: 32px;
                        height: 32px;
                        min-height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        color: $wodo-pag-item;
                        border: 1px solid $wodo-pag-item;
                        margin: 0 4px;
                        border-radius: 8px;

                        &:hover,
                        &:active {
                            background: $main-gradient-opacity;
                        }

                        &:first-child {
                            margin-left: 120px;
                        }

                        &:last-child {
                            margin-right: 20px;
                        }
                    }

                    .itemActive {
                        background: $main-gradient-opacity;
                        border: none;
                    }

                    .rotate180 {
                        bottom: 0;
                        right: 0;

                        & svg {
                            transform: rotate(180deg);
                        }
                    }

                    .rotate0 {
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    .ellipse {
        @include ellipseSeaWave;
        top: 35%;
        right: -35%;
        z-index: 0;
        transform: rotate(-120deg);
        opacity: 0.3;
        width: 496px;
        height: 367px;
    }
}

@media screen and (max-width: $laptop) {
    .supportWork {
        .supportWorkInner {
            flex-direction: column;

            .leftBlock {
                width: 100%;
                max-width: 900px;
                margin-bottom: 40px;

                .contactEmail {
                    font-size: 15px;
                    line-height: 140%;
                }
            }

            .rightBlock {
                height: 650px;
                max-height: 650px;
                min-width: 728px;
                width: 100%;
                max-width: 728px;
            }
        }
    }
}


@media screen and (max-width: $tablet) {
    .supportWork {
        margin-top: 110px;
        .supportWorkInner {
            .leftBlock {
                padding: 0;
            }

        }

        .ellipse {
            top: -15%;
            right: -25%;
            width: 380px;
            height: 270px;
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .supportWork {
        margin-top: 90px;
        .supportWorkInner {

            .rightBlock {
                height: 100%;
                max-height: 100%;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                border-radius: 0;
                background: transparent;
                justify-content: flex-start;

                .rightBlockInner {
                    padding: 0;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 565px;

                    .slider {
                        max-width: 302px;
                        .sliderItem {
                            min-width: 146px;
                            width: 146px;
                            max-width: 146px;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 130%;
                        }
                    }

                    .mainBlockRow {
                        width: 100%;

                        .mainBlockItem {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            width: 100%;

                            .mainBlockImgWrapper {
                                margin-top: 14px;
                                margin-bottom: 0;
                                & img {
                                    width: 196px;
                                    height: 372px;
                                }
                            }

                            .mainHint {
                                width: 100%;
                                max-width: 300px;
                                margin-left: 0;
                                margin-top: 0;
                                margin-bottom: 16px;
                                height: 80px;
                            }
                        }
                    }

                    .mainBlockColumn {
                        flex: 1 1;

                        .mainBlockItem {
                            align-items: center;
                            .mainBlockImgWrapper {
                                margin-left: 0;
                                margin-top: 68px;

                                & img {
                                    width: 375px;
                                    height: 270px;
                                }
                            }

                            .mainHint {
                                width: 100%;
                                max-width: 300px;
                                margin-left: 0;
                                margin-top: auto;
                                margin-bottom: 16px;
                                height: 80px;
                                text-align: center;
                            }
                        }
                    }

                    .pagination {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 36px;
                        height: 36px;
                        padding: 0 18px;
                        max-width: 270px;

                        .iconWrapper {
                            display: none;
                        }

                        .paginationItem {
                            min-width: 36px;
                            width: 36px;
                            height: 36px;
                            min-height: 36px;
                            margin: 0 15px;

                            &:hover,
                            &:active {
                                background: $main-gradient-opacity;
                            }

                            &:nth-child(2) {
                                margin-left: 0;
                            }

                            &:nth-child(5) {
                                margin-right: 0;
                            }
                        }
                    }
                }

                &:before {
                    content: none;
                }
            }
        }

        .ellipse {
            top: -15%;
            right: -45%;
            width: 380px;
            height: 270px;
        }
    }
}