@import '../../../styles/_colors';
@import '../../../styles/_variables';

.pricingMain {
    @include outerContainer;
    margin-top: 97px;

    .pricingInner {
        @include innerContainer;

        .sectionTitle {
            max-width: 360px;
            width: 100%;
            margin: 0 0 70px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            & span {
                max-width: 320px;
            }
        }

        .btnBlock {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 40px;

            .btn {
                @include gradientBorder;
                cursor: pointer;
                padding: 11px 18px;
                font-family: $main-font-family;
                font-weight: 900;
                font-size: 31px;
                line-height: 130%;
                min-width: 229px;

                &:hover,
                &:active {
                    background: $main-gradient-opacity;
                }
            }

            .active {
                background: $main-gradient-opacity;
            }
        }

        .tableBlock {
            @include gradientBorder;
            padding: 27px 44px 34px;

            table {
                width: 100%;
                border: none;
                border-collapse: collapse;

                & tr {
                    border-bottom: 1px solid $wodo-border-op ;

                    &:last-child {
                        border-right: none;
                    }
                }

                tbody {
                    tr {
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                & th {
                    border-left: 1px solid $wodo-border-op ;
                    border-right: 1px solid $wodo-border-op;
                    text-align: center;
                    padding: 19px;
                    height: 40px;

                    font-family: $main-font-family;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 31px;
                    line-height: 130%;
                    color: $wodo-black;

                    &:first-child {
                        border-left: none;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }

                & td {
                    border-left: 1px solid $wodo-border-op ;
                    border-right: 1px solid $wodo-border-op;
                    text-align: center;
                    padding: 15px 18px;
                    width: 271px;
                    max-width: 271px;

                    font-family: $side-font-family;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 130%;
                    color: $wodo-black;

                    &:first-child {
                        font-family: $main-font-family;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 110%;
                        text-align: left;
                        border-left: none;
                        padding-left: 13px;
                        padding-right: 21px;
                        width: 329px;
                        max-width: 329px;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }

                .tdLink span {
                    cursor: pointer;
                    text-decoration-line: underline;
                    color: $wodo-table-link;
                }

                .showFree {
                    tr {
                        td {
                            &:nth-child(3),
                            &:nth-child(4) {
                                display: none;
                            }
                            &:nth-child(2) {
                                border-right: none;
                            }
                        }
                    }
                }

                .showCloud {
                    tr {
                        td {
                            &:nth-child(2),
                            &:nth-child(4) {
                                display: none;
                            }
                            &:nth-child(3) {
                                border-right: none;
                            }
                        }
                    }
                }

                .showEnterprise {
                    tr {
                        td {
                            &:nth-child(2),
                            &:nth-child(3) {
                                display: none;
                            }
                            &:nth-child(4) {
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }

        .tableAnnotations {
            margin-top: 30px;
            margin-left: 23px;

            & p {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: $wodo-small-text;
            }
        }

        .btnWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: $tablet) {
    .pricingMain {
        margin-top: 110px;

        .pricingInner {
            .sectionTitle {
                margin: 0 0 40px 0;
            }

            .tableBlock {
                padding: 37px 44px 36px;
            }

            .tableAnnotations {
                margin-top: 30px;
                margin-left: 0;

                & p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: $wodo-small-text;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .pricingMain {
        margin-top: 90px;

        .pricingInner {
            .tableBlock {
                padding: 0;
                &:before {
                    content: none;
                }
                table {
                    & td {
                        text-align: center;
                        padding: 13px 0 13px 10px;
                        padding-left: 10px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 130%;

                        &:first-child {
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 110%;

                            padding-right: 10px;
                            padding-left: 0;
                        }
                    }
                }
            }
            .btnBlock {
                flex-direction: column;
                margin-bottom: 30px;
                .btn {
                    margin-bottom: 15px;
                    max-width: 100%;
                    font-weight: 900;
                    font-size: 21px;
                    line-height: 130%;
                    padding: 7px 18px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }


            .tableAnnotations {
                & p {
                    font-size: 12px;
                    line-height: 140%;
                }
            }
        }
    }
}
