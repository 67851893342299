@import '../../styles/_colors';
@import '../../styles/_variables';

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 90px;
    top: 0;
    width: 100%;
    z-index: 101;
    transition: all 0.3s ease;
    background-color: transparent;

    .headerInner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 14px 0;

        .headerLogo {
            cursor: pointer;
            min-width: 162px;
            width: 162px;
            min-height: 43px;
            height: 43px;
        }

        .navMenu {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            ul {
                display: flex;
                flex-direction: row;
                list-style-type: none;

                .navItem {
                    cursor: pointer;
                    font-size: 18px;
                    line-height: 22px;
                    padding: 0;
                    color: $wodo-black;
                    text-decoration: none;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin: 0 25px;
                    white-space: nowrap;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .arrowDown {
                        margin-left: 9px;
                        width: 13px;
                        height: 9px;
                    }

                    &:hover,
                    &:active {
                        color: $wodo-sea-wave;

                        .arrowDown {
                            & path {
                                stroke: $wodo-sea-wave;
                            }
                        }
                    }
                }
            }

            .dropdownList {
                @include gradientBorder;
                background: $main-gradient-opacity;
                padding: 28px;
                position: absolute;
                top: 40px;
                left: 0;
                flex-direction: column;

                li {
                    cursor: pointer;
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 17px;

                    &:hover,
                    &:active {
                        color: $wodo-sea-wave;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .dropdownUs {
                top: 40px;
                left: 180px;
            }
        }

        .mobileMenu,
        .mobileMenuClosed {
            display: none;
        }
    }

    .rotate180 {
        transform: rotate(180deg);
    }
}


.hideHeader {
    top: -93px;
}

.blurHeader {
    background-color: $wodo-transparent-white;
    backdrop-filter: blur(30px);
}

@media screen and (max-width: $laptop) {
    .header {
        .headerInner {
            padding: 0 25px;
        }
    }
}

@media screen and (max-width: $laptop-header) {
    .header {
        .headerInner {
            .navMenu {
                display: none;
            }

            .mobileMenu {
                display: flex;

                .mobileMenuIcon {
                    cursor: pointer;
                    display: block;
                    z-index: 150;
                    width: 20px;
                    height: 16px;
                    position: relative;
                    margin: 0;
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    -webkit-transition: .5s ease-in-out;
                    -moz-transition: .5s ease-in-out;
                    -o-transition: .5s ease-in-out;
                    transition: .5s ease-in-out;

                    & span {
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: $wodo-hr-grey;
                        opacity: 1;
                        left: 0;
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                        -webkit-transition: .25s ease-in-out;
                        -moz-transition: .25s ease-in-out;
                        -o-transition: .25s ease-in-out;
                        transition: .25s ease-in-out;

                        &:nth-child(1) {
                            top: 0;
                        }

                        &:nth-child(2),
                        &:nth-child(3) {
                            top: 7px;
                        }

                        &:nth-child(1) {
                            top: 14px;
                        }
                    }
                }

                .mobileIconOpened {
                    & span {
                        &:nth-child(1) {
                            top: 16px;
                            width: 0;
                            left: 50%;
                        }

                        &:nth-child(2) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }

                        &:nth-child(3) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }

                        &:nth-child(4) {
                            top: 16px;
                            width: 0;
                            left: 50%;
                        }
                    }
                }

                .mobileMenuWrapper {
                    display: flex;
                    background-color: $wodo-white;
                    position: absolute;
                    top: 0;
                    right: 0;
                    min-height: 100vh;
                    height: 100%;
                    max-height: 100vh;
                    overflow: auto;

                    .mobileMenuContainer {
                        @include pixelBg;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        //min-height: 100vh;
                        height: 100%;
                        background-color: $wodo-wrapper;
                        min-width: 320px;
                        flex: 1;
                        max-width: 320px;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 63px 5px 14px 23px;

                        overflow: auto;
                        max-height: 100vh;

                        .navMenuMobile {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            position: relative;

                            user-select: none;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;

                            ul {
                                display: flex;
                                flex-direction: column;
                                list-style-type: none;
                                padding: 0;
                                margin: 0;

                                & li {
                                    font-family: $main-font-family;
                                    font-weight: 600;
                                    cursor: pointer;
                                    font-size: 19px;
                                    line-height: 130%;
                                    padding: 0;
                                    color: $wodo-black;
                                    text-decoration: none;
                                    margin-bottom: 16px;

                                    &:hover,
                                    &:active {
                                        color: $wodo-sea-wave;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .header {
        height: 50px;

        .headerInner {
            padding: 9px;

            .headerLogo {
                min-width: 120px;
                width: 120px;
                height: 31.59px;
                min-height: 31.59px;
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .header {
        .headerInner {
            .mobileMenu {
                .mobileMenuWrapper {
                    .mobileMenuContainer {
                        padding: 70px 5px 25px 10px;

                        .navMenuMobile {
                            ul {
                                & li {
                                    margin-bottom: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $extra-small-mobile) {
    .header {
        .headerInner {
            .mobileMenu {
                .mobileMenuWrapper {
                    .mobileMenuContainer {
                        min-width: 270px;
                    }
                }
            }
        }
    }
}