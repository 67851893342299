@import '../../../styles/_colors';
@import '../../../styles/_variables';

.container {
    cursor: pointer;
    @include checkboxTemplate;

    .checkbox {
        @include checkbox;

        .checkboxMark {
            width: 14px;
            height: 14px;
        }
    }

    & span {
        font-size: 14px;
        line-height: 140%;
    }
}
