@import '../../styles/_colors';
@import '../../styles/_variables';

.footer {
    height: 100vh;
    width: 100%;
    background-color: $wodo-black;
    overflow: hidden;

    .footerContainer {
        height: 100vh;
        background-color: $wodo-black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .wodoLogo {
            cursor: pointer;
            width: 100%;
            max-width: 340px;
            height: 100%;
            max-height: 88.15px;
            margin-bottom: 56px;
        }

        .footerInfo {
            display: flex;
            width: 100%;
            max-width: 1200px;
            margin-top: 120px;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;

            .infoItem {
                display: flex;
                flex-direction: column;
                max-width: 360px;
                width: 100%;
                color: $wodo-white;
                line-height: 130%;

                .infoTitle {
                    font-family: $main-font-family;
                    font-weight: 900;
                    font-size: 28px;
                    line-height: 130%;
                    font-style: normal;
                    margin-bottom: 18px;
                }

                .footerHr {
                    margin: 0;
                    padding: 0;
                    height: 1px;
                    opacity: 0.4;
                    border-top: 1px solid $wodo-hr-grey;
                    width: 100%;
                }

                .infoText {
                    margin-top: 25px;
                }

                .raketaLogo {
                    cursor: pointer;
                    width: 128px;
                    height: 60px;
                    margin-top: 15px;
                }

                .storesWrapper {
                    display: flex;
                    flex-direction: row;
                    margin-top: 15px;

                    .googlePlayIcon {
                        cursor: pointer;
                        width: 162px;
                        height: 48px;
                        margin-right: 16px;
                    }

                    .appStoreIcon {
                        cursor: pointer;
                        width: 144px;
                        height: 48px;
                    }
                }

                .contactBlocks {
                    display: flex;
                    flex-direction: column;
                    margin-top: 25px;
                    margin-bottom: 31px;

                    .contactBlocksText {
                        max-width: 275px;
                        margin-top: 6px;
                    }

                    a {
                        color: $wodo-white;

                        &:hover,
                        &:active {
                            color: $wodo-sea-wave;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }

                &:nth-child(2) {
                    margin: 0 60px;
                }
            }
        }
    }
}

@media screen and (max-width: $laptop) {
    .footer {
        .footerContainer {
            padding: 0 25px;
        }
    }
}

@media screen and (max-width: $tablet) {
    .footer {
        height: 100%;
        .footerContainer {
            height: 100%;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            padding: 36px 22px 46px;

            & button,
            .wodoLogo {
                display: none;
            }

            .footerInfo {
                margin-top: 0;

                .infoItem {
                    max-width: 100%;
                    width: 100%;

                    .infoTitle {
                        font-weight: 900;
                        font-size: 26px;
                        line-height: 130%;
                    }

                    .infoText {
                        font-size: 15px;
                        line-height: 140%;
                    }

                    .storesWrapper {
                        flex-direction: column;

                        .googlePlayIcon {
                            margin-right: 0;
                            margin-bottom: 16px;
                        }
                    }

                    &:nth-child(2) {
                        margin: 0 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .footer {
        height: 100vh;
        .footerContainer {
            height: 100vh;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            padding: 50px 10px 79px;
            align-items: flex-start;

            .footerInfo {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .infoItem {
                    &:nth-child(2) {
                        margin: 60px 0;
                    }
                }
            }
        }
    }
}

