@import '../../../styles/_colors';
@import '../../../styles/_variables';

.firstSection {
    @include outerContainer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 100vh;
    z-index: 2;

    .topBlock {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        position: relative;

        .posterBlock {
            display: flex;
            width: 100%;
            max-width: 535px;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            margin-right: 41px;

            .mainTextBlock {
                display: flex;
                flex-direction: column;
                text-align: left;
                padding: 0;
                margin: 0 0 19px 0;
                width: 100%;
            }

            .mainSubtext {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 120%;
                color: $wodo-black;
                margin: 0 0 50px 0;
            }
        }
    }
}

@media screen and (max-width: $laptop) {
    .firstSection {
        padding-left: 25px;

        .topBlock {
            .posterBlock {
                .mainTextBlock {
                    h1 {
                        font-size: 64px;
                        line-height: 110%;
                    }

                    & h2 {
                        font-size: 32px;
                        line-height: 130%;
                    }

                    margin: 0 0 10px 0;
                }
            }
        }
    }
}


@media screen and (max-width: $tablet) {
    .firstSection {
        min-height: 100%;
        height: 100%;
        max-height: 1200px;
        justify-content: flex-start;
        padding: 0 20px;
        margin-bottom: 50px;

        .topBlock {
            margin-top: 600px;

            .posterBlock {
                .mainTextBlock {
                    & h1 {
                        font-size: 80px;
                        line-height: 110%;
                    }
                    & h2 {
                        font-size: 37px;
                        line-height: 130%;
                    }
                }

                .mainSubtext {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .firstSection {
        min-height: 100%;
        height: 100vh;
        max-height: 700px;
        padding: 0 10px;
        margin-bottom: 0;

        .topBlock {
            max-width: 100%;
            width: 100%;
            margin-top: 385px;

            .posterBlock {
                margin-right: 0;
                max-width: 100%;
                width: 100%;

                .mainTextBlock {
                    h1 {
                        font-size: 44px;
                        line-height: 110%;
                    }
                    & h2 {
                        font-size: 20px;
                        line-height: 130%;
                    }
                    margin: 0 0 10px 0;
                }

                .mainSubtext {
                    margin-bottom: 20px;
                    font-size: 15px;
                    line-height: 140%;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) and (max-height: $mobile-height) {
    .firstSection {
        min-height: 600px;
        height: 100%;
        max-height: 700px;
        padding: 0 10px;
        margin-bottom: 0;
    }
}