@import '../../styles/_colors';

.main {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    transition: filter 0.4s ease;
}

.mainBlurred {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
