@import '../../../styles/_colors';
@import '../../../styles/_variables';

.aboutUsMain {
    @include outerContainer;
    margin-top: 220px;

    .aboutUsMainInner {
        @include innerContainer;
        flex-direction: row;
        justify-content: space-between;

        .leftBlock {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 360px;
            padding-top: 145px;
            margin-right: 60px;

            .sectionTitle {
                width: 100%;
                margin: 0 0 40px 0;

                & span {
                    max-width: 350px;
                }
            }
        }

        .parallaxWrapper {
            display: flex;
            position: relative;
            height: 680px;
            max-height: 680px;
            overflow: hidden;
            width: 100%;
            max-width: 780px;
            border-radius: 32px;

            img {
                position: absolute;
                right: 0;
                margin: 0 auto;
                height: 115%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}


@media screen and (max-width: $tablet) {
    .aboutUsMain {
        margin-top: 90px;

        .aboutUsMainInner {
            flex-direction: column;
            justify-content: flex-start;

            .leftBlock {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                max-width: 100%;
                padding-top: 0;
                margin-right: 0;
                margin-bottom: 30px;

                .sectionTitle {
                    & span {
                        max-width: 100%;
                    }
                }
            }

            .parallaxWrapper {
                height: 446px;
                max-height: 446px;
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .aboutUsMain {
        margin-top: 110px;

        .aboutUsMainInner {
            .leftBlock {
                margin-bottom: 30px;
                .sectionTitle {
                    margin: 0 0 30px 0;
                }
            }

            .parallaxWrapper {
                height: 311px;
                max-height: 311px;

                & img {
                    height: 110%;
                }
            }
        }
    }
}
