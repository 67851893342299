@import '../../../styles/_colors';
@import '../../../styles/_variables';

.numbersMain {
    @include outerContainer;
    margin-top: 164px;

    .numbersMainInner {
        @include innerContainer;
        flex-direction: row;
        justify-content: space-between;

        .block {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 340px;
            z-index: 3;

            .number {
                font-family: $main-font-family;
                font-style: normal;
                font-weight: 900;
                font-size: 54px;
                line-height: 130%;
                color: $wodo-black;
                margin-bottom: 34px;
            }

            .text {
                font-size: 14px;
                line-height: 140%;
            }

            &:nth-child(2) {
                margin: 0 30px;

                .text {
                    max-width: 250px;
                }
            }

            &:last-child {
                .text {
                    max-width: 250px;
                }
            }
        }
    }

    .ellipse {
        @include ellipseSeaWave;
        width: 496px;
        height: 367px;
        right: 3%;
        bottom: -165%;
        opacity: 0.3;
        filter: blur(70px);
        transform: rotate(-120deg);
        z-index: 0;
    }
}

@media screen and (max-width: $tablet) {
    .numbersMain {
        margin-top: 110px;

        .numbersMainInner {
            .block {
                max-width: 300px;

                .number {
                    font-weight: 900;
                    font-size: 36px;
                    line-height: 130%;
                    color: $wodo-black;
                    margin-bottom: 20px;
                }

                .text {
                    font-size: 15px;
                    line-height: 140%;
                }

                &:nth-child(2) {
                    .text {
                        max-width: 100%;
                    }
                }

                &:last-child {
                    .text {
                        max-width: 100%;
                    }
                }
            }
        }

        .ellipse {
            @include ellipseSeaWave;
            width: 350px;
            height: 270px;
            right: -150px;
            bottom: -150px;
            opacity: 0.3;
            filter: blur(70px);
            transform: rotate(-120deg);
            z-index: 0;
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .numbersMain {
        margin-top: 90px;

        .numbersMainInner {
            flex-direction: column;
            justify-content: flex-start;

            .block {
                max-width: 100%;
                margin: 0 0 45px 0;

                .number {
                    font-weight: 900;
                    font-size: 44px;
                    line-height: 110%;
                    margin-bottom: 15px;
                }

                &:nth-child(2) {
                    margin: 0 0 45px 0;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

