@import '../../../styles/_colors';
@import '../../../styles/_variables';

.wodoFor {
    @include outerContainer;
    margin-top: 196px;

    .wodoForInner {
        @include innerContainer;
        flex-direction: row;

        .sectionTitle {
            max-width: 330px;
            width: 100%;
            margin: 0 94px 0 0;
            height: 276px;
            max-height: 276px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .scrollContentWrapper {
            display: flex;
            flex: 1;
            width: 100%;
            height: 100%;

            .scrollContent {
                width: 100%;
                height: 100%;

                .animationWrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    height: 200vh;
                    max-height: 1600px;
                    position: relative;

                    .itemsWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100vh;
                        max-height: 100vh;

                        .itemInner {
                            @include gradientBorder;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -webkit-flex-direction: column;
                            -ms-flex-direction: column;
                            -webkit-box-align: center;
                            -webkit-align-items: center;
                            -ms-flex-align: center;
                            flex-direction: column;
                            -webkit-box-pack: center;
                            -webkit-justify-content: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            align-items: center;
                            width: 360px;
                            max-width: 360px;
                            height: 360px;
                            max-height: 360px;
                            padding: 18px;
                            border-radius: 24px;

                            & img {
                                width: 324px;
                                max-width: 324px;
                                height: 324px;
                                max-height: 324px;
                                border-radius: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.textItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    margin-left: 60px;
    min-width: 300px;
    width: 360px;
    max-width: 360px;
    min-height: 320px;

    .itemTitle {
        font-family: $main-font-family;
        font-weight: 900;
        font-size: 21px;
        line-height: 130%;
        margin-bottom: 30px;
    }

    .itemText {
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        margin-bottom: 60px;
    }

    .btnPossibilities {
        @include gradientBorder;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 20px;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        .btnPossibilitiesName {
            font-family: $main-font-family;
            font-weight: 600;
            font-size: 21px;
            line-height: 130%;
            margin-right: 20px;
        }

        & img {
            width: 100%;
            height: 100%;
            max-width: 24px;
            max-height: 24px;
        }
    }

    .gradientBlock {
        @include gradientBorder;
        padding: 17px 20px 30px;
        border-radius: 16px;
        background: $main-gradient-opacity;

        .topTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 31px;

            .itemTopTitle {
                font-family: $main-font;
                font-weight: 600;
                font-size: 21px;
                line-height: 130%;
                margin-right: 20px;
            }

            .itemTopTitleIcon {
                cursor: pointer;
            }
        }

        .marksBlock {
            display: flex;
            flex-direction: column;

            .markText {
                display: flex;
                flex-direction: row;
                font-size: 17px;
                line-height: 130%;
                margin-bottom: 25px;
                max-width: 280px;

                & span {
                    margin-left: 12px;
                }

                .checkIcon {
                    min-width: 12px;
                    width: 12px;
                    min-height: 8px;
                    height: 8px;
                    margin-top: 8px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.smallBlock {
    display: flex;
    flex-direction: column;

    .blockItem {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 75px;

        .leftPart {
            width: 229px;
            max-width: 229px;
            height: 229px;
            max-height: 229px;
            padding: 11.45px;
            border-radius: 24px;
            border: 1px solid $wodo-border-grey;
            margin-right: 20px;

            & img {
                width: 206px;
                max-width: 206px;
                height: 206px;
                max-height: 206px;
                border-radius: 16px;
            }
        }

        .rightPart {
            display: flex;
            flex-direction: column;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


@media screen and (max-width: $laptop) {
    .wodoFor {
        .wodoForInner {
            .sectionTitle {
                margin-right: 0 !important;
            }

            .scrollContentWrapper {
                .scrollContent {
                    .animationWrapper {
                        .itemsWrapper {
                            .itemInner {
                                width: 229px;
                                max-width: 229px;
                                height: 229px;
                                max-height: 229px;
                                padding: 11.45px;
                                border-radius: 16px;

                                & img {
                                    width: 206px;
                                    max-width: 206px;
                                    height: 206px;
                                    max-height: 206px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .textItem {
        display: flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        margin-left: 30px;
        min-width: 280px;
        width: 100%;
        max-width: 360px;
    }
}

@media screen and (max-width: $tablet) {
    .wodoFor {
        margin-top: 110px;
        .wodoForInner {
            flex-direction: column;
            .sectionTitle {
                margin: 0 0 40px 0;
                height: 100%;
                max-height: 100%;
            }

            .scrollContentWrapper {
                display: none;
            }
        }
    }

    .textItem {
        position: relative;
        min-width: 467px;
        width: 100%;
        max-width: 100%;
        min-height: 229px;
        height: 100%;
        margin-left: 0;

        .itemTitle {
            font-weight: 600;
            font-size: 21px;
            line-height: 130%;
        }

        .itemText {
            font-size: 15px;
            line-height: 140%;
            margin-bottom: 49px;
        }

        .btnPossibilities {
            max-width: 250px;

            & img {
                width: 100%;
                height: 100%;
                max-width: 20px;
                max-height: 20px;
            }
        }

        .gradientBlock {
            position: absolute;
            width: 100%;
            max-width: 100%;
            z-index: 10;
            padding-bottom: 30px;
            padding-left: 26px;

            .topTitle {
                margin: 0 0 21px 0;
                .itemTopTitle {
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 130%;
                }
            }

            .marksBlock {
                .markText {
                    width: 100%;
                    max-width: 100%;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 140%;
                    margin-bottom: 17px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .wodoFor {
        margin-top: 90px;
        .wodoForInner {
            .sectionTitle {
                margin-bottom: 40px;
            }
        }
    }


    .smallBlock {
        display: flex;
        flex-direction: column;

        .blockItem {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 50px;

            .leftPart {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 500px;
                height: 100%;
                max-height: 500px;
                padding: 15px;
                margin-right: 0;
                margin-bottom: 25px;
                align-self: center;

                & img {
                    width: 100%;
                    max-width: 450px;
                    height: 100%;
                    max-height: 450px;
                    border-radius: 16px;
                }
            }

            .rightPart {
                display: flex;
                flex-direction: column;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .textItem {
        position: relative;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 229px;
        height: 100%;
        margin-left: 0;

        .itemTitle {
            margin-bottom: 15px;
        }

        .itemText {
            margin-bottom: 25px;
        }

        .btnPossibilities {
            width: 100%;
            max-width: 100%;
        }

        .gradientBlock {
            position: relative;
        }
    }
}
