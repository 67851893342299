@import '../../../styles/_colors';
@import '../../../styles/_variables';

.platformFunc {
    @include outerContainer;
    margin-top: 110px;
    margin-bottom: 70px;

    .platformFuncInner {
        @include innerContainer;

        .sectionTitle {
            max-width: 330px;
            width: 100%;
            margin: 0 0 70px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            & span {
                max-width: 300px;
            }
        }

        .itemsWrapper {
            display: flex;
            width: 100%;
            flex-direction: column;

            .advantageBlock {
                @include gradientBorder;

                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 16px 20px 16px;
                margin-bottom: 20px;

                .heading {
                    user-select: none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                .lowerBlock {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    margin-bottom: 10px;

                    .lowerBlockItem {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 15px;

                        & span {
                            margin-left: 12px;
                            font-size: 17px;
                            line-height: 130%;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .advantageBlockOpened {
                background: $main-gradient-opacity;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .platformFunc {
        margin-top: 110px;
        margin-bottom: 0;

        .platformFuncInner {
            .sectionTitle {
                margin: 0 0 40px 0;
            }

            .itemsWrapper {
                .advantageBlock {
                    .heading {
                        & h3 {
                            font-weight: 600;
                            font-size: 21px;
                            line-height: 130%;
                        }
                    }

                    .lowerBlock {
                        .lowerBlockItem {
                            & span {
                                font-size: 15px;
                                line-height: 140%;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $mobile-landscape) {
    .platformFunc {
        margin-top: 90px;
        margin-bottom: 0;

        .platformFuncInner {
            .sectionTitle {
                margin: 0 0 30px 0;
            }
        }
    }
}
