$wodo-white: #FFFFFF;
$wodo-transparent-white: rgba(255, 255, 255, 0.5);
$wodo-black: #282828;
$wodo-sea-wave: #48B6BB;
$wodo-orange: #FF8D00;
$wodo-block-bg: #E4E4E4;
$wodo-icon-border: rgba(255, 255, 255, 0.1);
$wodo-hr-grey: #909090;
$wodo-small-text: #616161;
$wodo-border-op: rgba(97, 97, 97, 0.4);
$wodo-table-link: #0094FF;

$wodo-grey-gradient: #ABABAB;
$wodo-gradient-from: #F4D9B6;
$wodo-gradient-to: #D3E9EA;

$wodo-gr-from-op: rgba(255, 141, 0, 0.15);
$wodo-gr-to-op: rgba(34, 153, 160, 0.15);

$wodo-wrapper: rgba(231, 231, 231, 0.8);
$wodo-popup-border: rgba(40, 40, 40, 0.4);
$black: #000000;
$wodo-red: #FF0000;

$wodo-border-grey: rgba(144, 144, 144, 0.4);
$wodo-pag-item: rgba(144, 144, 144, 0.5);
$wodo-slider: #B6B5B5;
$wodo-support-wrapper: rgba(255, 255, 255, 0.3);
