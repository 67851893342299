@import '../../../styles/_colors';
@import '../../../styles/_variables';

.trustUsMain {
    @include outerContainer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 75px;
    margin-bottom: 190px;

    & h3 {
        font-weight: 500;
        font-size: 19px;
        line-height: 130%;
        margin-bottom: 16px;
    }

    .trustUsHr {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 1px;
        border: none;
        border-top: 1px solid $wodo-black;
    }

    .brandsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 18px 0 19px;

        & span {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .brandsPg {
            min-width: 66.44px;
            min-height: 49.73px;
            width: 100%;
            height: 100%;
            max-width: 66.44px;
            max-height: 49.73px;
        }

        .brandsJti {
            min-width: 106.31px;
            min-height: 43.52px;
            width: 100%;
            height: 100%;
            max-width: 106.31px;
            max-height: 43.52px;
        }
    }

    .mobileBrands {
        display: none;
        .mobileBrandsRows {
            display: none;
            .openLogo {
                display: none;
            }
        }
    }
}

.sepBlock {
    width: 100%;
    margin: 190px auto 0;

    & h2 {
        max-width: 330px;
        margin-bottom: 70px;
    }
}

@media screen and (max-width: $laptop-header) {
    .trustUsMain {
        .brandsWrapper {
            flex-wrap: wrap;
            justify-content: space-around;

            & span {
                margin-right: 52px;
                margin-left: 0;

                &:nth-child(2) {
                    margin-bottom: 28px;
                }

                &:last-child {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .trustUsMain {
        padding-right: 25px;
        margin-bottom: 110px;
    }

    .sepBlock {
        width: 100%;
        margin: 110px auto 0;

        & h2 {
            max-width: 400px;
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: $mobile-landscape) {
    .trustUsMain {
        padding-right: 25px;
        margin-bottom: 90px;

        .brandsWrapper {
            display: none;
        }

        .mobileBrands {
            display: flex;
            flex-direction: column;
            padding: 0 4px;

            .mobileBrandsRows {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0;

                & span {
                    margin: 0;

                    .openLogo {
                        display: block;
                        width: 100%;
                        max-width: 135px;
                    }

                    &:first-child {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        min-width: 120px;
                        width: 100%;
                        max-width: 145px;

                        & svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &:last-child {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        max-width: 107px;
                    }
                }

                .brandsJti {
                    min-width: 106.31px;
                    min-height: 43.52px;
                    width: 100%;
                    height: 100%;
                    max-width: 106.31px;
                    max-height: 43.52px;
                }

                .brandsPg {
                    min-width: 66.44px;
                    min-height: 49.73px;
                    width: 100%;
                    height: 100%;
                    max-width: 66.44px;
                    max-height: 49.73px;
                }
            }
        }
    }

    .sepBlock {
        width: 100%;
        margin: 90px auto 0;

        & h2 {
            max-width: 400px;
            margin-bottom: 30px;
        }
    }
}