@import '../../../styles/_colors';
@import '../../../styles/_variables';

.container {
    @include checkboxTemplate;
    z-index: 5;

    .checkbox {
        @include checkbox;

        .checkboxMark {
            width: 14px;
            height: 14px;
        }
    }

    .smallerText {
        font-size: 12px;
        line-height: 15px;

        & a {
            font-size: 12px;
            line-height: 15px;
            color: $wodo-black;
            text-decoration: underline;

            &:hover,
            &:active {
                color: $wodo-black;
                text-decoration: underline;
            }
        }

        .errorText {
            color: $wodo-red;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            //padding-left: 20px;
        }
    }
}
